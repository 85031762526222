
.aes-header {
    display: flex;
    background-color: #01222D;
    color: #FFFFFF;
    justify-content: space-between;
    padding: 10px;
 
    .app-logo {
        display: flex;
        font-size: 18px;
 
        img {
            height: 30px;
        }
 
        .app-logo-text {
            margin-left: 5px;
        }
    }
 
    .user-name {
        color: #FFF;
        stroke: #FFF;
        background-color: #31455E;
        border: 0;
        width: 30px;
        height: 30px;
        border-radius: 16px;
        padding: 6px;
        line-height: 18px;
        text-align: center;
        box-sizing: border-box;
    }
}